.ante-protocol-widget--link {
  display: inline-block;
}

.ante-protocol-widget--container {
  position: relative;
  display: inline-flex;
}

.ante-protocol-widget--rating-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 12px;
  width: 20px;
  top: 50%;
  transform: translate(0%, -50%);
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #fff;
}
.ante-test-widget--link {
  display: inline-block;
}

.ante-test-widget--container {
  position: relative;
  display: inline-flex;
}

.ante-test-widget--score-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 16px;
  top: 10px;
  width: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #0064D6;
}
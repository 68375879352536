@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.tippy-box[data-theme~='ante'] {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 145%;
  background-color: #fff;
  color: #3B3B3B;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  padding: 8px;
  max-width: 320px;
}

.tippy-box[data-theme~='ante'] a {
  text-decoration: none;
  color: #3790b0;
}

.tippy-box[data-theme~='ante']>.tippy-arrow::before {
  color: #fff;
}